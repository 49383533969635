import { mapGetters } from "vuex";

export default {
  name: 'pay-details',
  computed: {
    ...mapGetters(['getOrderTarget'])
  },
  methods: {
    loadNextPage() {
      this.$router.push(`/quiz?id=q1`)
    }
  }
}